import { render, staticRenderFns } from "./index copy.vue?vue&type=template&id=634b4d88&scoped=true&"
import script from "./index copy.vue?vue&type=script&lang=js&"
export * from "./index copy.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "634b4d88",
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/workspace/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import WLoading from '@/components/WLoading.vue'
autoInstallComponents(component, {WLoading})
