<template>
  <div>
    <div class="p-5 bg-gray-200">
      <w-loading v-if="busy"></w-loading>
      <div v-else>
        <div
          class="bg-white dark:bg-gray-900 border border-gray-400 dark:border-gray-800 rounded-lg p-4 max-w-72 w-full"
        >
          <bar-chart
            :height="200"
            :data="data"
            :labels="labels"
            :options="{}"
            :currency="false"
            tick-format-off="tickFormat"
          />
        </div>

        <div class="grid gap-4 grid-cols-1 sm:grid-cols-2 my-2 w-full">
          <div
            class="bg-white dark:bg-gray-900 border border-gray-400 dark:border-gray-800 rounded-lg p-4 max-w-72 w-full"
            style="cursor: auto"
          >
            <a
              aria-label="Unsplash Downloads"
              target="_blank"
              rel="noopener noreferrer"
              href="https://stackdiary.com/"
            >
              <div
                class="flex items-center text-gray-900 dark:text-gray-100 text-sm"
                style="cursor: auto"
              >
                Ventas de hoy
              </div>
            </a>
            <p
              class="mt-1 text-2xl font-bold spacing-sm text-black dark:text-white"
              style="cursor: auto"
            >
              {{ ventasHoy.ars.totalAmount | formatearDinero }}
            </p>
            <!-- Subtitulo -->
            <p
              class="text-sm text-gray-600 dark:text-gray-400"
              style="cursor: auto"
            >
              {{ ventasHoy.ars.totalQuantity | formatearCantidad }}
              {{ ventasHoy.ars.totalQuantity > 1 ? "ventas" : "venta" }}
            </p>
          </div>
          <div
            class="bg-white dark:bg-gray-900 border border-gray-400 dark:border-gray-800 rounded-lg p-4 max-w-72 w-full"
            style="cursor: auto"
          >
            <a
              aria-label="Unsplash Downloads"
              target="_blank"
              rel="noopener noreferrer"
              href="https://stackdiary.com/"
            >
              <div
                class="flex items-center text-gray-900 dark:text-gray-100 text-sm"
                style="cursor: auto"
              >
                Ventas del mes
              </div>
            </a>
            <p
              class="mt-2 text-2xl font-bold spacing-sm text-black dark:text-white"
              style="cursor: auto"
            >
              {{ ventasMes.ars.totalAmount | formatearDinero }}
            </p>
            <!-- Subtitulo -->
            <p
              class="text-sm text-gray-600 dark:text-gray-400"
              style="cursor: auto"
            >
              {{ ventasMes.ars.totalQuantity | formatearCantidad }}
              {{ ventasMes.ars.totalQuantity > 1 ? "ventas" : "venta" }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BarChart from "@/components/charts/Bar";
export default {
  components: {
    BarChart,
  },
  name: "reporteAutores",
  layout: "autores",
  data() {
    return {
      busy: false,
      loading: false,
      page: 0,
      skip: 0,
      limit: 20,
      loaded: false,
      searchTerm: null,
      ventasMes: null,
      ventasHoy: null,
      ultimaSemana: null,
    };
  },
  async created() {
    this.busy = true;
    const ventasMes = await this.crearEstadistica({
      tipo: "mes-actual",
      autor: "casciari",
    });
    this.ventasHoy = await this.crearEstadistica({
      tipo: "dia-actual",
      autor: "casciari",
    });
    this.ultimaSemana = await this.crearEstadistica({
      tipo: "chart-semana",
      autor: "casciari",
    });

    this.ventasMes = ventasMes;
    this.busy = false;
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("orders", { findResultsInStore: "find" }),
    labels() {
      if (!this.ultimaSemana) return [];
      return this.ultimaSemana.data.map((item) => item.date);
    },
    data() {
      if (!this.ultimaSemana) return [];
      return this.ultimaSemana.data.map((item) => item.totalQuantity);
    },
  },
  methods: {
    ...mapActions("orders", { findResults: "find" }),
    ...mapActions("estadisticas-autores", { crearEstadistica: "create" }),
  },
  filters: {
    formatearDinero(value) {
      return new Intl.NumberFormat("es-AR", {
        style: "currency",
        currency: "ARS",
        maximumFractionDigits: 0,
      }).format(value);
    },
    formatearCantidad(value) {
      return new Intl.NumberFormat("es-AR", {
        maximumFractionDigits: 0,
      }).format(value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
